import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {locale, addLocale} from 'primereact/api';
import "highlight.js/styles/vs2015.css"
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"


const Allyourz = React.lazy(() => import('./Allyourz'));
const Bonaire = React.lazy(() => import('./Bonaire'));

addLocale('nl', {
  firstDayOfWeek: 1,
  dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
  dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  dayNamesMin: ['Z', 'M', 'D', 'W', 'D', 'V', 'S'],
  monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
  monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  today: 'Vandaag',
  clear: 'Schoonmaken'
})

locale('nl');

/**
 * defines the theme
 * @returns {JSX.Element}
 */
const renderSwitch = () => {
  switch (process.env.REACT_APP_BRAND){
    case 'allyourz.nl':
      return <Allyourz/>
    case 'bonaire.com':
      return <Bonaire/>
    default:
      return <Allyourz/>
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<>Loading...</>}>
      {renderSwitch()}
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
